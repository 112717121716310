import { supabase } from "../config/supabase";

export const useFetchUser = async () => {
  const userId = localStorage.getItem("userId");
  try {
    const { data, error } = await supabase
      .from("user_info")
      .select()
      .eq("uuid", userId);
    if (error) {
    }
    if (data.length > 0) {
      const { data: orgUser, error: orgError } = await supabase
        .from('organization_user')
				.select(
					'*, organization_id (id, name, email, available_seat, organization_type_id(id, name, fee), created_at)'
				)
				.or(`user_ids.cs.{${userId}},producer_ids.cs.{${userId}}`);
      if (orgError) {
      }
      if (orgUser.length > 0) {
        return {
          user: data[0],
          organization: orgUser[0].organization_id,
        };
      } else {
        return {
          user: data[0],
        };
      }
    }
  } catch (error) {
  }
};

export const useFetchOrganizationUser = async (id) => {
  try {
		if (!id) return;
		const { data: orgUser } = await supabase
			.from("organization_user")
			.select()
			.eq("organization_id", id);

		let organization_user = [];
		let producer_user = [];
		if (orgUser !== null && orgUser.length > 0) {
			const { data, error } = await supabase
				.from("user_info")
				.select()
				.in("uuid", orgUser[0].user_ids);
			organization_user.push(...data);
			if (error) {
			}
			const { data: producer, error: producer_error } = await supabase
				.from("user_info")
				.select()
				.in("uuid", orgUser[0].producer_ids);
			if (producer_error) {
			}
			producer_user.push(...producer);
		}
		if (organization_user.length > 0) {
			return { organization_user, producer_user };
		}
  } catch (error) {
  }
};
