export const constant = {
	teams: "Teams",
	role: "Role",
	participant: "Participant",
	gameLink: "Game link",
	emergency: "Emergency",
	ambulanceDiversions: "Ambulance Diversions",
	patientsInWaiting: "Patients in Waiting",
	arrivalsWaiting: "Arrivals Waiting",
	requestsWaiting: "Requests Waiting",
	extraStaff: "Extra Staff",
	surgery: "Surgery",
	stepDown: "Step Down",
	criticalCare: "Critical Care",
	participants: "Participants",
	firstName: "First Name",
	lastName: "Last Name",
	facilitator: "Facilitator",
	coFacilitator: "Co-Facilitator",
	producer: "Producer",
	email: "Email",
	login: "Login",
	password: "Password",
	removefrom: "Remove from",
	unassign: "Unassign",
	resetGameLink: "Reset game link",
	leftGame: "Left the game",
	member: "participants",
	unassigned: "unassigned",
	participantAssigned: "All participants assigned",
	manageSession: "Manage Session",
	left: "left the game",
	invoiceUnpaid: "Invoice unpaid",
	unpaidParticipants: "unpaid participants",
	waitingforPO: "Waiting for PO",
	group: "Group",
	setup: "Setup",
	sessionHeaderTooltip:
		"Share this secure link with participants to facilitate their payment.",
	participantLink: "Participant payment link:",
	virtual: "Virtual",
	fridayNight: "Friday Night at the ER®",
	signIn: "Sign In",
	forgotPassword: "Forgot Password?",
	randomizeAssignments: "Randomize Assignments",
	scheduleSession: "Schedule a new session",
	teamParform:
		"Compare scores against the typical ranges of scores for first-time and experienced players",
	teamScore: "Team Performance Scores",
	groupBehaviorTool:
		"Teams earn stars when they show behaviors that align with the three core strategies for success. For example, 1 star is awarded if all participants open the hospital view at least 3 times during the game. This is based on the assumption that managing with a big picture view is an indicator of collaboration.",
	showScore: "Show typical score ranges",
	gameMessage: "Friday Night at the ER® | Since 1992 | Learn more at",
	fridayNightEmail: "fridaynightattheer.com",
	newPassword: "New password",
	confirmNewPassword: "Confirm new password",
	updatePassword: "Update Password",
	totalPaperwork: "Total from Department Paperwork",
	editSessionError: "The minimum number of participants is 4.",
	openGameLink: "Open Game Links",
	copySessionLink: "Copy Session Link",
	copiedSessionLink: "Copied",
	copySessionLinkTooltip: "Send this link to participants when assignments are final (usually by pasting the copied link into the meeting chat).",
	notStart: "Not started",
	// paidWithSeats:"Paid with available seats",
	paidWithSeats: "Paid using available seats",
	AllParticipantsPaid: "All participants paid",
	dateIsUpcoming: "Date is upcoming",
	scheduledforToday: "Scheduled for today",
};

export const modal = {
	addParticipantModalTitle: "Add New Participant",
	addParticipantTooltipText:
		"This field is optional. Add participant email addresses if you prefer to have this information all in one place.",
	addParticipantText:
		"We will never share, sell, or misuse participant information.",
	addParticipantButtonText: "Add Participant",
	cancleSessionModal: "Cancel Session?",
	cancleSessionModalText:
		"Cancelling this session will erase participant data and session details.",
	cancleSessionModalBodyText:
		"Any seats that have already been paid for will credit to your account.",
	cancleSessionModalQuestion: "Are you sure you want to proceed?",
	cancleSessionNevermind: "Nevermind",
	challengeModalTital: "Challenge: Reorganize!",
	challengeModalText:
		"At the start of the next simulated hour, each participant will be notified about the reorganization and will automatically be reassigned to manage a different department.",
	challengeModalIssueButtonText: "Issue Reorganization",
	cancle: "Cancel",
	editParticipantModalTital: "Edit Participant",
	editModalFirstTooltip:
		"This field is optional. Add participant email addresses if you prefer to have this information all in one place.",
	editModalSecondTooltip:
		"We will never share, sell, or misuse participant information.",
	emailAddress: "Email Address",
	privateNoteTooltip: "Private notes will not be seen by participants",
	privateNote: "Private Note",
	privateNoteModelTooltip:
		"Add any information to help stay organized (e.g., participant’s relationship to the group, special needs or other notes)",
	saveChanges: "Save Changes",
	editTimeModalTital: "Edit Time Remaining",
	editTimeModalBodyText:
		"Editing the time remaining allows you to shorten or extend the gameplay. Once the time remaining runs out, the game will end for all participants, even for teams who haven’t finished the simulated 24-hour period.",
	editTimeModalAdjustment:
		"Participants will be notified about the time adjustment.",
	applyButtonText: "Apply",
	importantModalTital: "IMPORTANT:",
	importantModalBody: "Before you start, be sure all participants are",
	importantModalBreakout: "in their breakout rooms.",
	importantModalBodyText:
		"Once you allow participants to start, their orientation videos will begin and they will go on to play the game with their teams.",
	importantModalButtonText: "Allow participants to start",
	joinModalTitalText: "Are you sure?",
	joinModalBodyText:
		"You are about to join a department that is assigned to a participant. You will be able to observe, or you may take action as if you are the department manager.",
	joinModalButtonText: "Join",
	paushModalTitalText: "Pause Countdown",
	puashModalBodyText:
		"Pause the time remaining countdown for all participants until you resume it. Use this feature to give teams more time to finish the game, to facilitate a short group debrief in the middle of the gameplay in the main room, or to help manage technical issues or other disruptions that may arise.",
	puashModalBodyPara:
		"Participants will notified about the pause and the countdown for time remaining will be on hold.",
	puashModalButtonText: "Pause Countdown",
	removePlayerModalTitle: "Are you sure?",
	removePlayerModalBodyText: "Removing this participant will pause their game screen and notify them that they have been unassigned. The rest of the team will be invited to manage the unassigned department in a new browser tab.",
	removePlayerModalButtonsSubmit: "Remove participant",
	removePlayerModalCancel: "Cancel",
	openGameLinkBodyText: "Are you sure you’re ready to proceed? Once the game links are open, you won’t be able to make any further changes.",
	openGameLinkButtonText: "Open Game Links",
	openGameLinkTitalText: "Open Game Links",
	sessionModalBodyText:
		"Fill in your session details below. You can always edit these details later.",
	sessionModalEditDetails:
		"Edit your session details or cancel it below. If you cancel, any seats that have already been paid for will credit to your account.",
	sessionModalLableText: "Session name (participants will see this)",
	sessionModalSessionDate: "Session date",
	sessionModalSelectFacilitator: "Select a facilitator",
	sessionModalSelectCoFacilitator: "Select a producer", //co-facilitator
	sessionModalNumberOfParticipants: "Number of participants (minimum 4)",
	sessionModalAmountDue: "Amount due",
	sessionModalRemaining: "remaining",
	sessionModalAvailableSeats: "Use available seats",
	sessionModalPaymentMethod: "Payment method",
	teamPerformanceModalTooltip:
		"The Blended Score is a composite metric that combines various performance indicators into a single, normalized average score. Lower scores indicate better performance.",
	teamPerformanceQuality: "Quality Loss",
	teamPerformanceExcess: "Excess Cost",
	blendedScore: "Blended Score:",
};

export const table = {
	time: "Time",
	remaining: "Remaining",
	simulated: "Simulated",
	hour: "Hour",
	behaviorIndicators: "Behavior Indicators",
	quality: "Quality",
	financial: "Financial",
	summary: "Summary",
	loss: "Loss",
	blended: "Blended",
	score: "Score",
	activity: "Activity",
	cost: "Excess Cost",
	collaborative: "Collaborative",
	innovative: "Innovative",
	dataDriven: "Data-Driven",
	comingSoon: "Coming soon",
	assign: "Assign",
	unassigned: "Unassigned",
	joined: "Joined",
	payment: "Payment",
	editParticipant: "Edit participant",
	removeParticipant: "Remove participant",
	paid: "Paid",
	unPaid: "Unpaid",
	addNew: "Add new",
	numberOfHoursNotCompleted: "Number of hours not completed",
	ifYouDidNot: "If you did not",
	completeHours: "complete 24 hours",
	totalCost: "TOTAL COST",
	financialtotalCost: "TOTAL EXCESS COST",
	qualitytotalCost: "TOTAL QUALITY LOSS",
	next: "Next",
	joinAsFacilitator: "Join as facilitator",
	teamPerformance: "Team Performance",
	upcomingSessions: "Upcoming Sessions",
	pastSessions: "Past Sessions",
	scheduleNewSession: " Schedule a New Session",
	availableSeats: "Available seats:",
	logout: "Log out",
	facilitatorLogin: "Admin Login",
	notReady: "Not ready",
	ready: "Ready"
};
